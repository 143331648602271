import { UserAgentApplication, LogLevel } from "msal";

export const requiresInteraction = errorMessage => {
    if (!errorMessage || !errorMessage.length) {
        return false;
    }
    return (
        errorMessage.indexOf("consent_required") > -1 ||
        errorMessage.indexOf("interaction_required") > -1 ||
        errorMessage.indexOf("login_required") > -1
    );
};

export const fetchMsGraph = async (url, accessToken) => {
    const response = await fetch(url, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });
    return response.json();
};

export const isIE = () => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ") > -1;
    const msie11 = ua.indexOf("Trident/") > -1;
    return msie || msie11;
};

export const GRAPH_SCOPES = {
    OPENID: "openid",
    PROFILE: "profile",
    USER_READ: "User.Read",
    MAIL_READ: "Mail.Read"
};

export const GRAPH_ENDPOINTS = {
    ME: "https://graph.microsoft.com/v1.0/me",
    MAIL: "https://graph.microsoft.com/v1.0/me/messages"
};

export const GRAPH_REQUESTS = {
    LOGIN: {
        scopes: [
            GRAPH_SCOPES.OPENID,
            GRAPH_SCOPES.PROFILE,
            GRAPH_SCOPES.USER_READ
        ]
    },
    EMAIL: {
        scopes: [GRAPH_SCOPES.MAIL_READ]
    }
};

const authLocal = {
    clientId: "d7ee4dda-1b93-4768-8b95-5350aa6d7621",
    authority: "https://login.microsoftonline.com/8c86a4d9-5f4e-46d4-bde0-3502e9c3fdda",
    validateAuthority: true,
    postLogoutRedirectUri: "https://isolationbytrybamonbureau.traefik.me/logout",
    navigateToLoginRequestUrl: false,
    redirectUri: "https://isolationbytrybamonbureau.traefik.me/login",
};

const authPreprod = {
    clientId: "640f06d6-58be-409a-8f1c-72d0a1ddb987",
    authority: "https://login.microsoftonline.com/8c86a4d9-5f4e-46d4-bde0-3502e9c3fdda",
    validateAuthority: true,
    postLogoutRedirectUri: "https://recette-monbureau.isolationbytryba.fr/logout",
    navigateToLoginRequestUrl: false,
    redirectUri: "https://recette-monbureau.isolationbytryba.fr/login",
};

const authProd = {
    clientId: "2410e2c8-7a8b-4651-92b9-7a3baccde83a",
    authority: "https://login.microsoftonline.com/8c86a4d9-5f4e-46d4-bde0-3502e9c3fdda",
    validateAuthority: true,
    postLogoutRedirectUri: "https://monbureau.isolationbytryba.fr/logout",
    navigateToLoginRequestUrl: false,
    redirectUri: "https://monbureau.isolationbytryba.fr/login",
};

let authConfig = null;

switch(window.location.host) {
    case 'localhost:3000':
    case '0.0.0.0:8383':
    case 'localhost:8383':
    case 'isolationbytrybamonbureau.traefik.me':
        authConfig = {
            auth: authLocal,
            cache: {
                cacheLocation: "sessionStorage",
                storeAuthStateInCookie: isIE()
            },
            system: {
                navigateFrameWait: 0,
                loggerOptions: {
                    loggerCallback: (level, message, containsPii) => {
                        if (containsPii) {
                            return;
                        }
                        switch (level) {
                            case LogLevel.Error:
                                console.error(message);
                                return;
                            case LogLevel.Info:
                                console.info(message);
                                return;
                            case LogLevel.Verbose:
                                console.debug(message);
                                return;
                            case LogLevel.Warning:
                                console.warn(message);
                                return;
                            default:
                                return;
                        }
                    }
                }
            }
        };
        break;
    case 'recette-monbureau.isolationbytryba.fr':
        authConfig = {
            auth: authPreprod,
            cache: {
                cacheLocation: "sessionStorage",
                storeAuthStateInCookie: isIE()
            },
            system: {
                navigateFrameWait: 0,
                loggerOptions: {
                    loggerCallback: (level, message, containsPii) => {
                        if (containsPii) {
                            return;
                        }
                        switch (level) {
                            case LogLevel.Error:
                                console.error(message);
                                return;
                            case LogLevel.Info:
                                console.info(message);
                                return;
                            case LogLevel.Verbose:
                                console.debug(message);
                                return;
                            case LogLevel.Warning:
                                console.warn(message);
                                return;
                            default:
                                return;
                        }
                    }
                }
            }
        };
        break;
    case 'monbureau.isolationbytryba.fr':
        authConfig = {
            auth: authProd,
            cache: {
                cacheLocation: "sessionStorage",
                storeAuthStateInCookie: isIE()
            },
            system: {
                navigateFrameWait: 0,
                loggerOptions: {
                    loggerCallback: (level, message, containsPii) => {
                        if (containsPii) {
                            return;
                        }
                        switch (level) {
                            case LogLevel.Error:
                                console.error(message);
                                return;
                            case LogLevel.Info:
                                console.info(message);
                                return;
                            case LogLevel.Verbose:
                                console.debug(message);
                                return;
                            case LogLevel.Warning:
                                console.warn(message);
                                return;
                            default:
                                return;
                        }
                    }
                }
            }
        };
        break;
}

export const msalConfig = authConfig;

export let msalApp = new UserAgentApplication(msalConfig);
