import React from "react";
import { useObservable } from "../hooks/observable";
import ErrorService from "../services/ErrorService";
import {ErrorModel} from "../models/ErrorModel";
import ToastMessage from "../components/Toast/toast";

export const ErrorContext = React.createContext([]);

export const ErrorProvider: React.FunctionComponent = (props: any) => {

    const collection = useObservable(ErrorService.collection);

    return (
        <ErrorContext.Provider value={collection}>
            <>
                { collection.length ?
                    collection.map((error: ErrorModel, index: number) => {
                        if ( error.promise ) {
                            return (
                                <ToastMessage key={index} options={error.options} message={error.message} onDidDismiss={() => ErrorService.didClose(error)} />
                            );
                        } else {
                            return (
                                <ToastMessage key={index} options={error.options} message={error.message} onDidDismiss={() => ErrorService.remove(error)} />
                            );
                        }
                    })
                : null }
            </>
        </ErrorContext.Provider>
    );
};
