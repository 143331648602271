import React, {useEffect, useState} from 'react';
import {IonLoading} from '@ionic/react';

import "./Dashboard.scss";
import Title from "../../components/Title/Title";
import Card from "../../components/Card/Card";
import Header from "../../components/Header/Header";


import ApplicationService from "../../services/ApplicationService";
import Filters from "../../components/Filters/Filters";
import {BASE_SERVER_URL} from "../../config/axios";
import DashboardLoader from "../../components/DashboardLoader/DashboardLoader";
import openInNewTab from "../../utils/OpenInNewTab";
import Modal from "../../components/Modal/Modal";
import SearchBar from "../../components/SearchBar/SearchBar";
import BookmarkService from "../../services/BookmarkService";
import {copy} from "dauphine-js";
import {useObservable} from "../../hooks/observable";
import UserService from "../../services/UserService";
import Notification from "../../components/Notification/Notification";


const Dashboard = () => {

    const [loaded, setLoaded] = useState(false);
    const [currentCategory, setCurrentCategory] = useState(null);
    const searchTerms = useObservable(UserService.searchTerm);
    const [modal, setModal] = useState({ show : false});
    const [loadedClass, setLoadedClass] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [isNotificationOpened, setIsNotificationOpened] = useState(false);

    const bookmarks = useObservable(BookmarkService.bookmarks);


    useEffect(() => {

        if (!loaded) {
            loadDatas().then(_ => {
                afterLoading();
            });
        } else {
            if (currentCategory === 'bookmarks' && !testBookmarkPresence()) {
                setCurrentCategory(null);
            }
        }

    });

    const afterLoading = () => {
        BookmarkService.getMemory('category').then(category => {
            if (category !== null) {
                setCurrentCategory(category);
            }
            setLoaded(true);
        });
        if(sessionStorage.getItem('notification_closed') !== 'true') {
            setIsNotificationOpened(true);
        }
        setTimeout(() => setLoadedClass(true), 300);
    };

    const addBookmark = (appId) => {
        BookmarkService.add({
            appId : appId
        });
    };

    const removeBookmark = (appId) => {
        BookmarkService.remove({
            appId : appId
        });
    };

    const loadDatas = async () => {
        //if (!ApplicationService.list.length || !ApplicationService.categories.length) {
            await ApplicationService.getApplications(true);
            await ApplicationService.getCategories(true);
        //}
    };

    const getCategoriesFilters = () => {
        let list = ApplicationService.categories;
        if (testBookmarkPresence()) {
            list = [];
            list.push({
                'slug': 'bookmarks',
                'name': 'Favoris'
            });
            ApplicationService.categories.map(el => list.push(el));
            return list;
        }
        return list;
    };

    const testBookmarkPresence = (appId = null) => {
        if (appId !== null) {
            return bookmarks.findIndex(book => {
                return book.appId === appId
            }) >= 0;
        } else {
            return !!bookmarks.length;
        }
    };

    const changeCategorie = (category) => {
        BookmarkService.setMemory('category', category);
        setCurrentCategory(category)
    };

    const sortAppsLogic = (a, b) => {
        const bpa = testBookmarkPresence(a.id);
        const bpb = testBookmarkPresence(b.id);
        if (bpa) {
            if(bpb) return a.position > b.position ? 0 : -1;
            return -1;
        } else {
            return a.position > b.position || bpb ? 1 : -1;
        }
    };

    return (
        <div className={'dashboard'}>
            <header className={'dashboard__header'}>
                <Header/>
            </header>
            <div className={'dashboard__content ' + (loadedClass ? 'dashboard__content--loaded' : '')}>
                <Notification
                    onClose={() => {
                        setIsNotificationOpened(false);
                        sessionStorage.setItem('notification_closed', 'true');
                    }}
                    isOpened={isNotificationOpened}
                    title={'Bonjour ' + (UserService.user.get().firstname ? UserService.user.get().firstname : 'Prénom')}
                    text={""}
                />
                {
                    loaded ?
                        <div className={'container-fluid'}>
                            {
                                ApplicationService.list.filter((application) => application.featured).length ?
                                    <div className={'row'}>
                                        <Title type={'h5'} className={'dashboard__title'}>{"Applications à la une"}</Title>
                                    </div>
                                    : null
                            }
                            <div className={'row dashboard__featured-apps'}>
                            {
                                ApplicationService.list
                                    .filter((application) => application.featured)
                                    .sort((appA, appB) => sortAppsLogic(appA, appB))
                                    .map((application, key) => {

                                    const bookmarked = testBookmarkPresence(application.id);
                                    return (
                                        <div key={key} className={'dashboard__card dashboard__card--highlight'}>
                                            <Card
                                                highlight={true}
                                                starred={bookmarked}
                                                icon={
                                                    {
                                                        src : BASE_SERVER_URL + application.icon.src,
                                                        alt : application.icon.alt,
                                                    }
                                                }
                                                title={application.title}
                                                description={application.description_short}
                                                onBookmark={() => {
                                                    if(bookmarked === true) {
                                                        removeBookmark(application.id)
                                                    } else {
                                                        addBookmark(application.id)
                                                    }
                                                }}
                                                onInfo={() => setModal({
                                                    show: true,
                                                    headline: 'Application',
                                                    title: application.title,
                                                    icon : {
                                                        src : BASE_SERVER_URL + application.icon.src,
                                                        alt : application.icon.alt,
                                                    },
                                                    shortText :  application.description_short,
                                                    description : application.description
                                                })}
                                                onClick={() => {
                                                    openInNewTab(application.url_desktop)
                                                }}
                                            />
                                        </div>
                                    );
                                })
                            }
                            </div>


                            {
                                ApplicationService.list.length ?
                                    <>
                                    <div className={'row'}>
                                        <Title type={'h5'} className={'dashboard__title'}>Toutes vos applications</Title>
                                    </div>

                                    <div className={'row dashboard__filters'}>
                                        <Filters
                                            items={getCategoriesFilters()}
                                            current={currentCategory}
                                            onSelect={(category) => changeCategorie(category)}
                                        />
                                        <SearchBar

                                        />
                                    </div>

                                    <div className={'row'}>

                                        {

                                            ApplicationService.list
                                                .filter((application) => {
                                                    if (currentCategory === 'bookmarks') {
                                                        if (!testBookmarkPresence(application.id)) return false;
                                                        if (searchTerms !== null) {
                                                            return application.title.trim().toLowerCase().indexOf(searchTerms) > -1;
                                                        }
                                                        return true;
                                                    }
                                                    if (currentCategory === null && searchTerms === null) {
                                                        return true;
                                                    } else if(currentCategory === null && searchTerms !== null) {
                                                        return application.title.trim().toLowerCase().indexOf(searchTerms) > -1
                                                    } else if(currentCategory !== null && searchTerms === null) {
                                                        return application.categories.indexOf(currentCategory) > -1;
                                                    }

                                                    return application.categories.indexOf(currentCategory) > -1 && application.title.trim().toLowerCase().indexOf(searchTerms) > -1;

                                                })
                                                .sort((appA, appB) => sortAppsLogic(appA, appB))
                                                .map((application, key) => {

                                                    const bookmarked = bookmarks.findIndex(book => {
                                                        return book.appId === application.id
                                                    }) >= 0;

                                                    return (
                                                        <div key={key} className={'dashboard__card'}>
                                                            <Card
                                                                starred={bookmarked}
                                                                icon={
                                                                    {
                                                                        src : BASE_SERVER_URL + application.icon.src,
                                                                        alt : application.icon.alt,
                                                                    }
                                                                }
                                                                title={application.title}
                                                                description={application.description_short}
                                                                onInfo={() => setModal({
                                                                    show: true,
                                                                    headline: 'Application',
                                                                    title: application.title,
                                                                    icon : {
                                                                        src : BASE_SERVER_URL + application.icon.src,
                                                                        alt : application.icon.alt,
                                                                    },
                                                                    shortText :  application.description_short,
                                                                    description : application.description
                                                                })}
                                                                onBookmark={() => {
                                                                    if(bookmarked === true) {
                                                                        removeBookmark(application.id)
                                                                    } else {
                                                                        addBookmark(application.id)
                                                                    }
                                                                }}
                                                                onClick={() => {
                                                                    openInNewTab(application.url_desktop)
                                                                }}
                                                            />
                                                        </div>
                                                    );
                                                })
                                        }

                                    </div>

                                    </>
                                    : null
                            }
                        </div>
                    : <DashboardLoader/>
                }
                <Modal
                  headline={modal.headline}
                  title={modal.title}
                  icon={modal.icon}
                  shortText={modal.shortText}
                  description={modal.description}
                  cta={modal.cta}
                  cta2={modal.cta2}
                  picture={modal.picture}
                  notification={modal.notification}
                  video={modal.video}
                  date={modal.date}
                  onClose={() => {
                      let modalcopy = copy(modal);
                      modalcopy.show = false;
                      setModal(modalcopy);
                  }}
                  show={modal.show}
                />
            </div>
            <IonLoading
                cssClass={'w-loader'}
                isOpen={showLoading}
                spinner={'dots'}
                translucent={true}
            />
        </div>
    );
};

export default Dashboard;
