import React from "react";
import {Switch, Route, Redirect, useHistory, BrowserRouter} from 'react-router-dom';
import routes from "../config/routes";
import UserService from "../services/UserService";
import {useObservable} from "../hooks/observable";
import AuthMsal from "../components/AuthMsal/AuthMsal";
import {ORIGIN_SSO_SERVER_URL} from "../config/axios";

/**
 * Allow to check user state and display the right route when loading app
 * @returns {null}
 * @constructor
 */
const AccessControl = () => {
    const history = useHistory();
    const user = UserService.user.get();
    if (!user.isLogged && history.location.pathname !== '/connexion' && history.location.pathname !== '/login' && history.location.pathname !== '/saml-login') {
        history.push('/connexion');
    } else if (user.isLogged && history.location.pathname === '/connexion') {
        history.push('/');
    }
    return null;
};

const SamlLogin = () => {
    window.location.href = `${ORIGIN_SSO_SERVER_URL}/apps/samlsp/login`;
    return null;
}

/**
 * Displaying routes
 * @returns {*}
 * @constructor
 */
const Router = () => {
    const user = useObservable(UserService.user);
    return (
        <BrowserRouter>
            <AccessControl />
            <Switch>
                {
                    Array.isArray(routes) && routes.length &&
                        routes.map((route, i) => {
                            if (route.name === 'msalLogin') {
                                // Special case to force some msal props
                                return (
                                  <Route path={route.path} component={() => <AuthMsal afterRedirection={true} />} key={i} exact={true} />
                                );
                            } else if (route.name === 'samlLogin') {
                                // Special case to force some msal props
                                return (
                                  <Route path={route.path} component={() => <SamlLogin />} key={i} exact={true} />
                                );
                            } else {
                                if(route.private === undefined || (route.private === true && user.isLogged)) {
                                    return(
                                      <Route path={route.path} component={route.component} key={i} exact={true} />
                                    )
                                }
                            }
                            return null;
                        })
                }
                <Redirect to={'/connexion'}/>
            </Switch>
        </BrowserRouter>
    )
};

export default Router;
