import React from 'react';

import "./Filters.scss";

const Filters = props => {
  return (
    <ul className="filters">

        <li className={'filters__item' + (props.current === null ? ' filters__item--current':'')} onClick={() => props.onSelect(null)}>Tout voir</li>
        {
            props.items.map((item, key) => {
                return (<li key={key} className={'filters__item' + (props.current === item.slug ? ' filters__item--current':'')} onClick={() => props.onSelect(item.slug)}>{item.name}</li>)
            })
        }

    </ul>
  );
};

export default Filters;