
import { Observable } from "../hooks/observable";
import {ErrorModel} from "../models/ErrorModel";

// ============ Normal use
// ErrorService.add('test aaaa');
// ============ Async use
// ErrorService.addSync('test test').then(() => {
//     console.log('end add sync');
// });

class ErrorServiceClass
{
    readonly collection : Observable<any> = new Observable([]);

    public async addSync(message: string)
    {
        let error: ErrorModel = {
            message: message,
            type: 'toast',
        };
        if(-1 === this.collection.get().findIndex((err: ErrorModel) => error.message === err.message && error.type === err.type) ) {
            error.promise = new Promise((resolve)=> {
                error.resolve = resolve;
            });
            let collection = this.collection.get().filter((err: ErrorModel) => error.message !== err.message && error.type !== err.type);
            collection.push(error);
            this.collection.set(collection);
            return error.promise;
        } else {
            return new Promise((resolve, reject)=> {
                reject();
            });
        }
    }

    public add(message: string, options: any = {}) : boolean
    {
        let error: ErrorModel = {
            message: message,
            type: 'toast',
            options: options
        };
        if(-1 === this.collection.get().findIndex((err: ErrorModel) => error.message === err.message && error.type === err.type) ) {
            let collection = this.collection.get().filter((err: ErrorModel) => error.message !== err.message && error.type !== err.type);
            collection.push(error);
            this.collection.set(collection);
            return true;
        }
        return false;
    }

    public didClose(error: ErrorModel)
    {
        if (error && typeof error.promise !== 'undefined') {
            error.resolve();
            this.remove(error);
        }
    }

    public remove(error: ErrorModel)
    {
        this.collection.set( this.collection.get().filter((t: ErrorModel) => error !== error) );
    }

}
const  ErrorService = new ErrorServiceClass();
export default ErrorService;
