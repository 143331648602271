import React from 'react';

import Loader from 'react-loaders'

import "./DashboardLoader.scss";

const DashboardLoader = props => {
  return (
    <div className="dashboard-loader__container">
        <Loader type="square-spin" active />
    </div>
  );
};

export default DashboardLoader;