import {get, set} from "local-storage";
import {BookmarkModel} from "../models/BookmarkModel";
import {Observable} from "../hooks/observable";

class BookmarkServiceClass {

    readonly bookmarks : Observable<BookmarkModel[]> = new Observable([]);

    public getMemory = async (key: string) : Promise<any | null> => {
        return await get(key);
    };

    public setMemory = (key: string, data: any) : void => {
        set(key, data);
    };

    public add(bookmark: BookmarkModel) {
        const list = this.bookmarks.get();
        const newList = list.filter((bm: BookmarkModel) => bm.appId !== bookmark.appId);
        newList.push(bookmark);
        this.bookmarks.set(newList);
        this.setMemory('bookmarks', newList);
    }

    public remove(bookmark: BookmarkModel) {
        const list = this.bookmarks.get();
        const newList = list.filter((bm: BookmarkModel) => {return bm.appId !== bookmark.appId});
        this.bookmarks.set(newList);
        this.setMemory('bookmarks', newList);
    }

    async init() {
        return new Promise(async (resolve) => {
            this.getMemory('bookmarks')
                .then((bookmarks: BookmarkModel[] | null) => {
                    if(null !== bookmarks) {
                        this.bookmarks.set(bookmarks);
                    }
                    resolve();
            }).catch(_ => resolve);
        });
    }
}

const BookmarkService = new BookmarkServiceClass();

export default BookmarkService;
