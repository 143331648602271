import React from 'react';

import "./ScreensizeModal.scss";
import Icon from "../Icon/Icon";
import Title from "../Title/Title";

const ScreensizeModal = props => {
  return (
      <div className={'screensize-modal'}>
          <div className={'screensize-modal__content'}>
              <Icon icon={'screensize'} size={44} className={'screensize-modal__icon'}/>
              <Title type={'h5'} headline={true}>Taille d'écran</Title>
              <Title type={'h5'}>Veuillez agrandir votre fenêtre ou passer sur un écran plus grand</Title>
              <p className={'p'}>Cette application n’est pas compatible pour les smartphones et les petites résolutions</p>
          </div>
      </div>
  );
};

export default ScreensizeModal;