import React from 'react';

import "./SplashScreen.scss";
import {IonSpinner} from "@ionic/react";

const SplashScreen = props => {
  return (
      <div className={"splash-screen " + props.className}>
          <div className={"splash-screen__content"}>
              <IonSpinner color={"light"} name="dots" />
          </div>
      </div>
  );
};

export default SplashScreen;
