import React, {useRef, useState} from 'react';

import "./Modal.scss";
import Icon from "../Icon/Icon";
import Title from "../Title/Title";
import useOutsideClick from "../../utils/OutsideClick";
import {ColorExtractor} from "react-color-extractor";
import YouTube from 'react-youtube';

const Modal = props => {

    const opts = {
        height: '260',
        width: '460',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
        },
    };

    const [iconColor, setIconColor] = useState(null);

    const iconStyle = {
        backgroundColor: iconColor
    };

    const modalRef = useRef(null);
    useOutsideClick(modalRef, () => props.onClose());

    return (
        <div className={'modal-container ' + (props.show ? 'modal-container--show' : '')}>
            <div className={"modal" + (props.notification ? ' modal__notification' : '')} ref={modalRef}>
                <div className={'modal__close-button'} onClick={() => props.onClose()}>
                    <Icon icon={'close'} size={20}/>
                </div>
                <div className={'modal__header'}>
                    {
                        props.icon ?
                            <div className={'modal__header__icon'}>
                                <div className={'modal__header__icon__background'} style={iconStyle}/>
                                <ColorExtractor getColors={(colors) => setIconColor(colors[0])}>
                                    <img src={props.icon.src} alt={props.icon.alt}/>
                                </ColorExtractor>
                            </div>
                            :
                            null
                    }
                    {
                        props.picture ?
                            <div className={'modal__header__picture'} style={{"background-image": "url('" + props.picture.src + "')"}} />
                            :
                            null
                    }
                    {
                        props.video ?
                            <div className={'modal__header__video'}  >
                                <YouTube videoId={props.video.ref} opts={opts} onReady={(e) => e.target.pauseVideo()} />
                            </div>
                            :
                            null
                    }
                    <div className={'modal__header__text'}>
                        {
                            props.headline ?
                                <Title type={'h3'} headline={true}
                                       className={'modal__headline'}>{props.headline}</Title>
                                : null
                        }
                        {
                            props.title ?
                                <Title type={'h4'} className={'modal__title'}>{props.title}</Title>
                                : null
                        }
                        {
                            props.shortText ?
                                <p className={'p modal__description'}>{props.shortText}</p>
                                : null
                        }
                    </div>

                </div>
                {
                    props.description ?
                        <div className={'modal__content wysiwyg'} dangerouslySetInnerHTML={{__html: props.description}}/>
                        : null
                }
                {
                    props.cta ?
                        <div className={'modal__cta p'}>
                            <a className={"btn btn--small btn--primary"} href={props.cta.url} target={"_blank"} >{props.cta.label}</a>
                        </div>
                        : null
                }
                {
                    props.cta2 ?
                        <div className={'modal__cta p'}>
                            <a className={""} href={props.cta2.url} target={"_blank"} >{props.cta2.label}</a>
                        </div>
                        : null
                }
            </div>
        </div>
    );
};

export default Modal;
