import React from 'react';
import {GlobalProvider} from '../providers/GlobalProvider';
import Root from './Root';
import '../assets/styles/global.scss';

import '@ionic/react/css/core.css';
import '../theme/variables.scss';
import {ErrorProvider} from "../providers/ErrorProvider";

export default function App() {
    return (
        <GlobalProvider>
            <Root />
            <ErrorProvider />
        </GlobalProvider>
    );
}
