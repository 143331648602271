import Axios, {AxiosRequestConfig} from "axios";

export let BASE_API_URL = "";
export let BASE_SERVER_URL = "";
export let ORIGIN_SSO_SERVER_URL = "";

switch(window.location.host) {
    case 'localhost:3000':
    case '0.0.0.0:8383':
    case 'localhost:8383':
    case 'isolationbytrybamonbureau.traefik.me':
        BASE_SERVER_URL = 'https://isolationbytrybamonbureau.traefik.me';
        ORIGIN_SSO_SERVER_URL = 'https://isolationbytrybamonbureau.traefik.me';
        break;
    case 'recette-monbureau.isolationbytryba.fr':
        BASE_SERVER_URL = 'https://recette-monbureau.isolationbytryba.fr';
        ORIGIN_SSO_SERVER_URL = 'https://recette-monbureau.isolationbytryba.fr';
    break;
    case 'monbureau.isolationbytryba.fr':
        BASE_SERVER_URL = 'https://monbureau.isolationbytryba.fr';
        ORIGIN_SSO_SERVER_URL = 'https://monbureau.isolationbytryba.fr';
    break;
}

BASE_API_URL = BASE_SERVER_URL + '/apps/api';

const DEFAULT_CONFIG: AxiosRequestConfig = {
    headers: {
        'Content-Type': 'application/json'
    },
    baseURL: BASE_API_URL
};

Axios.defaults = DEFAULT_CONFIG;
export default Axios;
