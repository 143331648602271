import React from 'react';

import "./Title.scss";

const Title = props => {
    const Element = props.type;
    let className = (props.className ? props.className : '');

    if(props.headline) {
        className += ' headline'
    } else {
        className += ' ' + props.type;
    }

    return (
        <div className={'title-container'}>
            <Element className={className} onClick={props.onClick}>{props.children}</Element>
        </div>
    )
};

export default Title;