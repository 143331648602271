import React, {useState} from 'react';
import { ColorExtractor } from 'react-color-extractor'

import "./Card.scss";
import Icon from "../../components/Icon/Icon";


const Card = props => {

    const [iconColor, setIconColor] = useState(null);

    const iconStyle = {
        backgroundColor : iconColor
    };

    return (
    <div className={'card' + (props.highlight === true ? ' card--highlight' : '')}>
        <div className={'card__bookmark' + (props.starred ? ' card__bookmark--bookmarked' : '')} onClick={props.onBookmark}>
            { props.starred ? <Icon size={20} icon={'star'} /> : <Icon size={20} icon={'star-empty'} /> }

        </div>
        <div className={'card__content'} onClick={() => props.onClick()}>
            <div className={'card__icon'}>
                <div className={'card__icon__background'} style={iconStyle}/>
                <ColorExtractor getColors={(colors) => setIconColor(colors[0])}>
                    <img src={props.icon.src} alt={props.icon.alt} />
                </ColorExtractor>
            </div>
            <div className={'card__text'}>
                <h3 className={'card__title'}>{props.title}</h3>
                {/*<p className={'card__description'}>{props.description}</p>*/}
            </div>
        </div>
        {/*<div className={'card__info'} onClick={props.onInfo}>
            <Icon size={20} icon={'info'}/>
        </div>*/}
    </div>
  );
};

export default Card;
