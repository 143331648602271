import {Redirect, useParams} from 'react-router';
import React from 'react';
import {set} from "local-storage";

const OpenNotification = (props) => {

    let { id } = useParams();
    if (!isNaN(id) && id > 0) {
        set('notificationToOpen', id);
    }
    return <Redirect to='/'/>;

};

export default OpenNotification;
