import React, {useEffect, useState} from 'react';
import {IonToast} from "@ionic/react";

interface ToastMessagePropsType {
    message ?: string;
    onDidDismiss ?: Function;
    options ?: any;
}

const ToastMessage = (props :ToastMessagePropsType) => {

    const [showToast, setShowToast] = useState(true);

    useEffect(() => {
        if (!showToast && typeof props.onDidDismiss !== 'undefined') {
            props.onDidDismiss();
        }
    });

    return (
        <IonToast
            isOpen={showToast}
            onDidDismiss={() => setShowToast(false)}
            message={props.message ? props.message : "Nous avons recontré une erreur."}
            color={props.options && props.options.color ? props.options.color : "dark"}
            position={props.options && props.options.position ? props.options.position : "middle"}
            duration={props.options && props.options.duration ? props.options.duration : 0}
            buttons={[
                {
                    icon: 'close',
                    role: 'cancel',
                    handler: () => {

                    }
                }
            ]}
        />
    )

};

export default ToastMessage
