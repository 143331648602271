import React from 'react';
import iconSet from "../../assets/icons/selection";
import IcomoonReact from "icomoon-react";

import "./Icon.scss";

const Icon = props => {
  return (
      <div onClick={(e) => {
        return props.onClick ? props.onClick(e) : null
      }}>
        <IcomoonReact iconSet={iconSet} size={props.size}  icon={props.icon} className={props.className} />
      </div>
  );
};

export default Icon;
