import React from 'react';

import "./SearchBar.scss";
import Icon from "../Icon/Icon";
import {useObservable} from "../../hooks/observable";
import UserService from "../../services/UserService";
import {useStateValue} from "../../providers/GlobalProvider";

const SearchBar = props => {

  const searchTerms = useObservable(UserService.searchTerm);
  const [{currentLanguage, localizationContent}, dispatch] = useStateValue();

  const hasChanged = () => {
  };


  return (
    <div className={'searchbar-container'}>
      <div className="searchbar">
        {searchTerms === null ?
          <Icon size={18} icon={'search'} className={'searchbar__icon'}/>
          :
          <Icon size={18} icon={'close'} className={'searchbar__icon searchbar__icon--close'} onClick={() => { UserService.setSearchTerms(null); hasChanged(); }}/>
        }
        <input
            type={'text'}
            value={searchTerms === null ? '' : searchTerms}
            placeholder={(localizationContent && localizationContent.searchApp ? localizationContent.searchApp : "Rechercher une application")}
            className={'searchbar__input'}
            onChange={(event) => { UserService.setSearchTerms(event.target.value.length ? event.target.value.trim().toLowerCase() : null); hasChanged(); }}
        />
      </div>
    </div>
  );
};

export default SearchBar;
