import React, {createContext, useContext, useReducer} from 'react';

export const GlobalContext = createContext();

const initialState = {};

const reducer = (state, action) => {

    switch (action.type) {
        default:
            return state;
    }
};

export const GlobalProvider = (props) => (
    <GlobalContext.Provider value={useReducer(reducer, initialState)}>
        {props.children}
    </GlobalContext.Provider>
);

export const useStateValue = () => useContext(GlobalContext);
