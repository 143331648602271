export function setDatas(key, value, array, clean=false){
    let datas;
    if(clean){
        sessionStorage.removeItem(key);
    }
    if(array){
        datas = getDatas(key) || [];
        datas.push(array);
    }
    else{
        datas = value;
    }
    sessionStorage.setItem(key, JSON.stringify(datas));
}

export function getDatas(key){
    const datas = sessionStorage.getItem(key);
    return datas ? JSON.parse(datas) : null;
}

export function removeDatas(key){
    sessionStorage.removeItem(key);
}
