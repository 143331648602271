import axios, {AxiosRequestConfig} from "axios";
import {api} from "../config/api";
import UserService from "./UserService";
import {get, set} from "local-storage";
import openInNewTab from "../utils/OpenInNewTab";
import {useStateValue} from "../providers/GlobalProvider";
import {getDatas} from "../utils/Storage";

class ApplicationServiceClass {

    list = [];
    categories = [];

    public async init() {
        return new Promise(async (resolve, reject) => {
            if ( UserService.user.get().isLogged ) {
                await this.getApplications(false);
                await this.getCategories(false);
            }
            resolve();
        });
    }

    public async getApplications(forceGetDistant: boolean = false) {
        return new Promise((resolve, reject) => {
            this.getMemory('applications')
                .then((apps: any | null) => {
                    if (null === apps || true === forceGetDistant) {

                        this.getDistant(api.applications.list.replace('{language}', getDatas('language'))).then( async (data: any) => {
                            if (null !== data) {
                                this.list = data;
                                await this.setMemory('applications', data);
                            } else {
                                //ErrorService.add('Impossible de récupérer la liste des applications');
                                await this.setMemory('applications', null);
                                UserService.checkExpired();
                            }
                            resolve();
                        });
                    } else {
                        this.list = apps;
                        resolve();
                    }
                });
        });
    }

    public async getCategories(forceGetDistant: boolean = false) {
        return new Promise((resolve, reject) => {
            this.getMemory('categories')
                .then((categories: any | null) => {
                    if (null === categories || true === forceGetDistant) {
                        this.getDistant(api.applications.categories.replace('{language}', getDatas('language'))).then( async (data: any) => {
                            if (null !== data) {
                                this.categories = data;
                                await this.setMemory('categories', data);
                            } else {
                                //ErrorService.add('Impossible de récupérer la liste des catégories');
                                await this.setMemory('categories', null);
                            }
                            resolve();
                        });
                    } else {
                        this.categories = categories;
                        resolve();
                    }
                });
        });
    }

    public openApp(id: number) {
        const application = this.list.find((app: any) => app.id === id);
        if (application) {
            openInNewTab(application['url_desktop']);
        }
    }

    private getMemory = async (key: string) : Promise<any | null> => {
        return await get(key);
    };

    private getDistant = async (url: string) : Promise<any | null> => {
        return new Promise((resolve, reject) => {
            const config : AxiosRequestConfig = {
                headers: {
                    'Authorization': 'Bearer ' + UserService.user.get().token
                }
            };
            axios.get(axios.defaults.baseURL + url, config ).then(result => {
                if (result.status === 200) {
                    resolve(result.data);
                    return;
                }
                resolve(null);
            }).catch(error => {
                resolve(null);
            });
        });
    };

    private setMemory = (key: string, data: any) : void => {
        set(key, data);
    };

    private async clearDatas() {
        return new Promise(async (resolve, reject) => {
            this.list = [];
            await this.setMemory('applications', []);
            this.categories = [];
            await this.setMemory('categories', []);
            resolve();
        });
    }
}

const ApplicationService = new ApplicationServiceClass();

export default ApplicationService;
