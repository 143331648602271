/* PLOP_INJECT_IMPORT */
import Login from '../pages/Login/Login';
import OpenNotification from '../pages/OpenNotification/OpenNotification';
import Dashboard from '../pages/Dashboard/Dashboard';
import Test from '../pages/Test/Test';
import AuthMsal from "../components/AuthMsal/AuthMsal";

const routes = [
    {
        path: "/connexion",
        name: 'login',
        component: Login
    },
    {
        path: "/",
        name: 'dashboard',
        component: Dashboard,
        private: true,
    },
    {
        path: "/test",
        name: 'test',
        component: Test
    },
    {
        path: "/login",
        name: 'msalLogin'
    },
    {
        path: "/saml-login",
        name: 'samlLogin'
    },
    {
        path: "/logout",
        name: 'msalLogout',
        component: AuthMsal
    },
    {
        path: "/notification/:id",
        name: 'openNotification',
        component: OpenNotification
    },
];

export default routes;
