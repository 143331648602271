import React from 'react';

import "./Test.scss";
import Title from "../../components/Title/Title";
import Card from "../../components/Card/Card";
import Modal from "../../components/Modal/Modal";

const Test = props => {
  return (
    <div className="test">
      Test

        <Title type={'h1'}>Title level 1</Title>
        <Title type={'h2'}>Title level 2</Title>
        <Title type={'h3'}>Title level 3</Title>
        <Title type={'h4'}>Title level 4</Title>
        <Title type={'h5'}>Title level 5</Title>
        <Title type={'h2'} headline={true}>Headline</Title>

      <p className={'p'}>Lorem ipsum dolor sit amet, consectetur</p>
      <p className={'p--small'}>Lorem ipsum dolor sit amet, consectetur</p>
      <p className={'p--medium'}>Lorem ipsum dolor sit amet, consectetur</p>
      <p className={'p--large'}>Lorem ipsum dolor <b>sit amet</b>, consectetur</p>

        <Card/>
        <Card highlight={true}/>

        <Modal/>

    </div>
  );
};

export default Test;
