import React from 'react';

import "./Notification.scss";
import Icon from "../Icon/Icon";

const Notification = props => {
    return (
        <div className={"notification " + (props.isOpened ? 'notification--opened' : null)}>
            <div className={'notification__close'} onClick={() => props.onClose()}>
                <Icon size={24} icon={'close'}/>
            </div>
            <h2 className={'notification__title'}>{props.title}</h2>
            <p className={'notification__text'}>{props.text}</p>
        </div>
    );
};

export default Notification;