export interface UserModel {
    id: string | null;
    token: string;
    msalToken: string;
    company?: string;
    isLogged?: boolean;
    askForMsalReconnection?: boolean;
    askForReconnection?: boolean;
    pleaseRetry?: boolean;
}

export const DefaultUser : UserModel = {
    id: null,
    token: '',
    msalToken: '',
    company: 'Tryba',
    isLogged: false,
    askForMsalReconnection: false,
    askForReconnection: false,
    pleaseRetry: false,
};
