import axios, {AxiosRequestConfig} from "axios";
import {DefaultUser, UserModel} from "../models/UserModel";
import { Observable } from "../hooks/observable";
import {get, set} from "local-storage";
import {api} from "../config/api";
import {ORIGIN_SSO_SERVER_URL} from "../config/axios";
import ErrorService from "./ErrorService";
import {msalApp} from "../config/Msal";
import ApplicationService from "./ApplicationService";

class UserServiceClass
{
    readonly user : Observable<UserModel> = new Observable(DefaultUser);
    readonly searchTerm : Observable<string|null> = new Observable(null);

    intervalExpired : any = null;

    public dispatch(user: UserModel) {
        this.user.set({...this.user.get(), ...user});
    };

    public getMemory = async () : Promise<UserModel | null> => {
        return await get('userInMemory');
    };

    public setMemory = (user: UserModel) : void => {
        set('userInMemory', user);
    };

    public reconnect() : void {
        this.user.set({...this.user.get(), ...{askForReconnection: false, pleaseRetry: false}} );
        set('userInMemory', this.user.get());
    }

    public logout() {
        ErrorService.add('Vous êtes déconnecté', {'position': 'top', 'duration': 1000, 'color': 'primary'});
        this.user.set({...this.user.get(), ...{isLogged: false, token: ''}, ...{askForReconnection: true, pleaseRetry: false, askForMsalReconnection: false}});
        set('userInMemory', this.user.get());
        this.stopCheckExpired();
        setTimeout(() => {
            msalApp.cacheStorage.clear();
        }, 100);
    };

    public logoutWithMsal() {
        this.user.set({...this.user.get(), ...{isLogged: false, token: ''}, ...{askForReconnection: true, pleaseRetry: false, askForMsalReconnection: false}});
        set('userInMemory', this.user.get());
        this.stopCheckExpired();
        setTimeout(() => {
            msalApp.logout();
        }, 100);
    };

    public pleaseRetry() {
        this.user.set({...this.user.get(), ...{isLogged: false, token: ''}, ...{pleaseRetry: true}});
    };

    public handleMessage(e: any) {
        if (e.origin === ORIGIN_SSO_SERVER_URL && typeof e.data === 'string') {
            if (e.data.substring(0, 8) === 'success:') {
                const token = e.data.substring(8);
                UserService.getUserDatas(token, '').then((data) => {
                    if (null === data) {
                        ErrorService.add('Une erreur est survenue');
                        UserService.pleaseRetry();
                    }
                }).catch(_ => {
                    // Nok
                    ErrorService.add('Une erreur est survenue');
                    UserService.pleaseRetry();
                });
            } else if (e.data.substring(0, 4) === 'fail') {
                // Nok
                ErrorService.add(e.data.length === 4 ? 'Une erreur est survenue' : e.data.substring(5));
                UserService.pleaseRetry();
            }
            e.source.close();
        }
    }

    public stopAskForMsalReconnection() {
        UserService.user.set({...UserService.user.get(), ...{askForMsalReconnection: false}});
        set('userInMemory', UserService.user.get());
    }

    public handleVisibilityWindowsChange(e: any) {
        if (!document.hidden) {
            // Plus nécéssaire
            //UserService.checkExpired();
            if (UserService.user.get().isLogged) {
                setTimeout(async () => {
                    await ApplicationService.getApplications(true);
                }, 150);
            }
        } else {
            if (typeof UserService.searchTerm !== "undefined") {
                UserService.searchTerm.set(null);
            }
        }
    }

    public checkExpired() {
        // Dès qu'on revient dans la page on check si le token est toujours valide
        if ( UserService.user && UserService.user.get().token && UserService.user.get().isLogged) {
            UserService.getUserDatas(UserService.user.get().token, UserService.user.get().msalToken, true).then((data) => {
                if( null === data) {
                    ErrorService.add('Session expirée', {'position': 'top', 'duration': 3500, 'color': 'primary'});
                    UserService.user.set({...UserService.user.get(), ...{isLogged: false, token: '', askForReconnection: false, askForMsalReconnection: true}});
                    set('userInMemory', UserService.user.get());
                    UserService.stopCheckExpired();
                    setTimeout(() => {
                        msalApp.cacheStorage.clear();
                    }, 100);
                }
            });
        }
    }

    public setSearchTerms(term: string | null) {
        this.searchTerm.set(term);
    }

    public startCheckExpired() {
        if (this.intervalExpired !== null) {
            clearInterval(this.intervalExpired);
            this.intervalExpired = null;
        }
        this.intervalExpired = setInterval(this.checkExpired, 300000);
    }

    public stopCheckExpired() {
        if (this.intervalExpired !== null) {
            clearInterval(this.intervalExpired);
            this.intervalExpired = null;
        }
    }

    public init() {
        return new Promise((resolve => {
            window.addEventListener('message', this.handleMessage, false);
            document.addEventListener( 'visibilitychange' , this.handleVisibilityWindowsChange, false );
            setTimeout(() => {
                this.getMemory()
                    .then((userInMemory: UserModel | null) => {
                        if (userInMemory) {
                            // Si on recharge la page, ou souhaite toujours passer par la vérification sso
                            this.dispatch({...userInMemory, ...{askForReconnection: false, askForMsalReconnection: false}});
                            if(userInMemory.isLogged) {
                                this.startCheckExpired();
                            }
                            resolve();
                        } else {
                            resolve();
                        }

                    });
            }, 400);
        }));
    }

    public getUserDatas(token: string, msalToken: string, fromCheckExpired = false) {
        return new Promise((resolve => {
            const config : AxiosRequestConfig = {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            };
            axios.get(axios.defaults.baseURL + api.applications.user, config ).then(result => {
                if (result.status === 200) {
                    this.dispatch({...this.user.get(), ...result.data, ...{token: token, msalToken: msalToken, isLogged: true, askForReconnection: false}});
                    set('userInMemory', this.user.get());
                    if (!fromCheckExpired) {
                        setTimeout(() => this.startCheckExpired(), 100000);
                    }
                    resolve(result.data);
                    return;
                }
                if (!fromCheckExpired) {
                    this.logout();
                }
                resolve(null);
            }).catch(error => {
                if (!fromCheckExpired) {
                    this.logout();
                }
                resolve(null);
            });
        }));
    }

}

const  UserService = new UserServiceClass();
export default UserService;
